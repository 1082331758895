.code {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0;
}
.code__editor {
  visibility: hidden;
  opacity: 0;
  transition: all var(--animation-speed) ease;
}
.code__editor.visible {
  visibility: visible;
  opacity: 1;
}
.code__save-button {
  position: absolute;
  bottom: 5%;
  right: 25%;
  padding: 20px 40px;
  background-color: #FC0A7E;
  border-radius: 20px;
  transition: all var(--animation-speed) ease;
}
.code__save-button:hover {
  opacity: 0.7;
}
.code__show-code-editor {
  position: absolute;
  bottom: 30px;
  right: 5%;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 25px;
  cursor: url("https://cdn.custom-cursor.com/db/7171/32/minecraft-command-block-pointer.png"), pointer !important;
}

