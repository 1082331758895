.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  background-color: #1B1B1B;
  padding: 40px 0;
}
.footer__logo {
  width: fit-content;
  margin: auto;
}
.footer__nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

