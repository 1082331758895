.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: clamp(90%, 5.845rem - 0.94vw, 80%);
  margin: 40px auto;
  gap: 30px;
}
.header__logo {
  object-fit: cover;
}
.header__nav-list {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all var(--animation-speed) ease;
}
@media (max-width: 520px) {
  .header__nav-list {
    position: absolute;
    top: -2000%;
    left: -10%;
    width: 90vw;
    height: 100vh;
    background-color: #1B1B1B;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
  }
}
.header__nav-list.mobile-nav-list {
  visibility: visible;
  opacity: 1;
  top: -100%;
}
.header__item {
  font-size: 15px;
}

.burger-menu {
  position: relative;
  display: block;
  width: 30px;
  height: 18px;
  cursor: pointer;
  z-index: 1000;
  transition: all var(--animation-speed) ease;
}
@media (min-width: 520px) {
  .burger-menu {
    display: none;
  }
}
.burger-menu span {
  top: calc(50% - 1px);
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: all var(--animation-speed) ease;
}
.burger-menu span:first-child {
  top: 0;
}
.burger-menu span:last-child {
  top: auto;
  bottom: 0;
}
.burger-menu.burger-menu-active {
  transform: translateX(-240%);
}
.burger-menu.burger-menu-active span {
  transform: scale(0);
}
.burger-menu.burger-menu-active span:first-child {
  transform: rotate(135deg);
  top: calc(50% - 1px);
}
.burger-menu.burger-menu-active span:last-child {
  transform: rotate(45deg);
  bottom: calc(50% - 1px);
}

