.features {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 80px;

	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 40px;
		max-width: clamp(15.625rem, 2.839rem + 54.55vw, 51.938rem);
		margin: auto;
		&:nth-child(even) {
			flex-direction: row-reverse;

			@media (max-width: 910px) {
				flex-direction: column;
				text-align: center;
			}
		}
		@media (max-width: 910px) {
			flex-direction: column;
			text-align: center;
		}
	}
	// .features__article
	&__article {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 450px;
		gap: 30px;
	}

	// .features__text
	&__text {
	}

	// .features__title
	&__title {
		color: #000000;
	}

	// .features__desc
	&__desc {
		font-size: 16px;
		font-weight: 400;
		color: #6B6B6B;
	}

	// .features__img
	&__img {
		object-fit: cover;
		max-width: clamp(21.875rem, 18.574rem + 14.08vw, 31.25rem);
	}
	&__blocks {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		margin: 50px 20px;
		gap: 50px;
	}
	&__block {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		max-width: 400px;
		text-align: center;
	}
	&__feature-title {
		font-size: 18px;
		font-weight: 700;
		color: #000000;
	}
	&__feature-desc {
		color: #6B6B6B;
	}
	&__advantages {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		align-items: center;
		text-align: center;
		gap: 50px;
		margin: 0 20px 100px 20px;
	}
	&__advantage-title {
		font-size: 60px;
		font-weight: 700;
		color: #000000;
	}
	&__advantage-text {
		font-size: 18px;
		font-weight: 700;
		color: #000000;
	}
}