.info {
	position: relative;
	margin: 100px 30px 0 30px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	// .info__article
	&__article {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 30px;
		width: clamp(20rem, 11.197rem + 37.56vw, 45rem);
		margin: auto;
	}

	// .info__quote
	&__quote {
	}

	// .info__text
	&__text {
		font-size: 24px;
		font-weight: 400;
		color: #6B6B6B;
	}

	// .info__print
	&__print {
		display: flex;
		gap: 15px;
	}

	// .info__writer
	&__writer {
		font-size: 16px;
		font-weight: 400;
		color: #6B6B6B;
	}

	// .info__separator
	&__separator {
		width: 40px;
		height: 1px;
		margin: auto;
		background-color: #6B6B6B;
	}

	// .info__publisher
	&__publisher {
		font-size: 16px;
		font-weight: 700;
		color: #6B6B6B;
	}
	&__companies {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		justify-content: space-evenly;
		align-items: center;
	}
}