@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --animation-speed: 0.5s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100vh;
}

body {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  font-family: "Gothic A1", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;
  color: #ffffff;
}

a {
  text-decoration: none;
  color: #ffffff;
}

ul,
ol {
  list-style: none;
}

::-webkit-scrollbar {
  width: 0;
}

::selection {
  color: #ffffff;
  background-color: #6B6B6B;
}

.bg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 105vh;
  z-index: -100;
  background: linear-gradient(287.15deg, #450e4b 0%, #3c0c41 24.48%, rgba(207, 0, 99, 0) 100%), #cf0063;
}

#root {
  width: 100%;
}

.title {
  font-size: clamp(1.5rem, 1.192rem + 1.31vw, 2.375rem);
  font-weight: 400;
}

.text {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #A8A8A8;
}

.app-image {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  background-image: url("/public/assets/images/random-img.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

