.code {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 0;

	&__editor {
		visibility: hidden;
		opacity: 0;
		transition: all var(--animation-speed) ease;

		&.visible {
			visibility: visible;
			opacity: 1;
		}
	}

	&__save-button {
		position: absolute;
		bottom: 5%;
		right: 25%;
		padding: 20px 40px;
		background-color: #FC0A7E;
		border-radius: 20px;
		transition: all var(--animation-speed) ease;

		&:hover {
			opacity: 0.7;
		}
	}

	&__show-code-editor {
		position: absolute;
		//width: 36px;
		//height: 36px;
		bottom: 30px;
		right: 5%;
		padding: 15px;
		background-color: #ffffff;
		border-radius: 25px;
		cursor: url("https://cdn.custom-cursor.com/db/7171/32/minecraft-command-block-pointer.png"), pointer !important;
	}
}