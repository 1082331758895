.about {
	position: relative;
	width: 100%;


	&::before {
		content: "";
		position: absolute;
		object-fit: cover;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #1B1B1B;
		z-index: -110;
		overflow-x: hidden;
	}

	// .about__article
	&__article {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-content: center;
		width: clamp(90%, 5.845rem + -0.94vw, 80%);
		margin: auto;
		gap: 30px;
		padding-top: clamp(1.875rem, 0.775rem + 4.69vw, 5rem);
	}

	// .about__text
	&__text {
	}

	// .about__title
	&__title {
	}
}